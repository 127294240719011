import React from 'react';
import MainMap from "./MainMap";
import './App.css'

function App() {
    return (
        <MainMap/>
    );
}

export default App;
